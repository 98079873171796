import { FC, useState } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import { Spinner } from 'react-bootstrap'

type DocumentCardProps = {
  title: string
  description: string
  downloadLink: string
  icon: string
  setProcessing: (isProcessing: boolean) => void
}

const DocumentCard: FC<DocumentCardProps> = ({ title, description, downloadLink, icon, setProcessing }) => {
  const handleDownload = () => {
    setProcessing(true); // Show spinner
    setTimeout(() => {
      // Simulate download completion after 3 seconds
      const link = document.createElement('a');
      link.href = downloadLink;
      link.download = downloadLink.split('/').pop() || 'download.zip'; // Extract file name from URL
      link.click();
      setProcessing(false); // Hide spinner after "download"
    }, 3000); // Wait 3 seconds
  };

  return (
    <div className="col-xl-4">
      <div className="card card-xl-stretch mb-xl-8">
        <div className="card-body">
          <div className="d-flex align-items-center mb-5">
            <div className="symbol symbol-40px me-5">
              <span className={`symbol-label bg-light-${icon}`}>
                <i className={`bi bi-${icon} text-dark fs-2x`}></i>
              </span>
            </div>
            <div>
              <h4 className="text-dark">{title}</h4>
            </div>
          </div>
          <p className="text-gray-600 fs-5 mb-5">{description}</p>
          <button
            onClick={handleDownload}
            className="btn btn-light-primary"
          >
            Download as ZIP
          </button>
        </div>
      </div>
    </div>
  );
};

const SampleDocuments: FC = () => {
  const intl = useIntl();
  const [processing, setProcessing] = useState(false);

  return (
    <div className="container-xxl">
      {processing && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            zIndex: 100,
            backgroundColor: '#5e627861',
            position: 'fixed',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner animation="border" role="status" variant="light" style={{ width: '3rem', height: '3rem' }}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.SAMPLE_DOCUMENTS' })}
      </PageTitle>
      <div className="row g-5 g-xl-8">
        <DocumentCard
          title="Sample Invoices"
          description="Download sample invoices in a zip file for your reference."
          downloadLink="media/sampleZip/Invoice.zip"
          icon="file-earmark-text"
          setProcessing={setProcessing}
        />
        <DocumentCard
          title="Sample Bank Statements"
          description="Download sample bank statements in a zip file for your reference."
          downloadLink="media/sampleZip/Bank Statement.zip"
          icon="file-earmark-spreadsheet"
          setProcessing={setProcessing}
        />

        <DocumentCard
          title="Sample Handwritten Documents"
          description="Download sample handwritten statements in a zip file for your reference."
          downloadLink="media/sampleZip/Handwritten.zip"
          icon="file-pdf"
          setProcessing={setProcessing}
        />
      </div>
    </div>
  );
};

export { SampleDocuments };
