// import React, { useEffect, useState, useRef } from 'react';
// import { KTIcon } from '../../../../_metronic/helpers';
// import { DocumentPreviewProps } from '../DocumentProcessingModel';
// import { useAuth } from '../../auth';
// import { pdfjs } from 'react-pdf';  // Import pdf.js for PDF rendering

// // Set workerSrc for PDF.js to load the worker script
// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js`;

// export const DocumentPreview: React.FC<DocumentPreviewProps> = ({
//   fileName,
//   doctype,
//   fileLink,
// }) => {
//   const { currentUser } = useAuth();
//   const canvasRef = useRef<HTMLCanvasElement | null>(null);
//   const containerRef = useRef<HTMLDivElement | null>(null);  // Reference for the container
//   const isImage = /\.(jpeg|jpg|png|bmp|webp)$/i.test(fileLink); // Regex to detect image extensions
//   const isPdf = /\.pdf$/i.test(fileLink); // Regex to detect PDF extension
  
//   const [zoom, setZoom] = useState(1); // Zoom level state
//   const [imageDimensions, setImageDimensions] = useState<{ width: number, height: number }>({ width: 0, height: 0 });

//   const fitToScreen = () => {
//     // Fit content to screen (adjust zoom based on container size)
//     if (containerRef.current && canvasRef.current) {
//       const containerWidth = containerRef.current.offsetWidth;
//       const containerHeight = containerRef.current.offsetHeight;
//       const canvas = canvasRef.current;

//       // For Images
//       if (isImage && fileLink) {
//         const img = new Image();
//         img.onload = () => {
//           const aspectRatio = img.width / img.height;
//           const width = containerWidth;
//           const height = width / aspectRatio;
//           canvas.width = width;
//           canvas.height = Math.min(height, containerHeight);
//           setZoom(containerWidth / img.width); // Set zoom to fit width
//           setImageDimensions({ width: canvas.width, height: height });
//         };
//         img.src = fileLink;
//       } else if (isPdf && fileLink) {
//         // For PDFs: Use pdf.js to render the PDF page on canvas
//         const renderPdfPage = async () => {
//           const pdf = await pdfjs.getDocument(fileLink).promise;
//           const page = await pdf.getPage(1);  // Render first page (you can change the page number)
//           const viewport = page.getViewport({ scale: 1 });  // Apply scale 1 for normal size
//           const height = viewport.height;
//           canvas.width = containerWidth;
//           canvas.height = Math.min(height, containerHeight);
//           const renderContext = {
//             canvasContext: canvas.getContext('2d')!,
//             viewport: viewport,
//           };
//           await page.render(renderContext).promise;
//           setZoom(containerWidth / viewport.width); // Set zoom to fit width
//         };
//         renderPdfPage();
//       }
//     }
//   };

//   useEffect(() => {
//     // Ensure refs are not null before performing operations
//     if (containerRef.current && canvasRef.current) {
//       const containerWidth = containerRef.current.offsetWidth;  // Get container width
//       const canvas = canvasRef.current;
//       const ctx = canvas.getContext('2d');

//       // For Images
//       if (isImage && fileLink) {
//         const img = new Image();
//         img.onload = () => {
//           const aspectRatio = img.width / img.height;
//           const height = img.height * (containerWidth / img.width) * zoom;  // Adjust height based on width and zoom
//           canvas.width = containerWidth * zoom;  // Adjust width based on container width and zoom
//           canvas.height = height;  // Adjust height based on the calculated height
//           setImageDimensions({ width: canvas.width, height: height });
//           ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
//         };
//         img.src = fileLink;
//       } else if (isPdf && fileLink) {
//         // For PDFs: Use pdf.js to render the PDF page on canvas
//         const renderPdfPage = async () => {
//           const pdf = await pdfjs.getDocument(fileLink).promise;
//           const page = await pdf.getPage(1);  // Render first page (you can change the page number)
//           const viewport = page.getViewport({ scale: zoom });  // Apply zoom scale to the viewport
//           const height = viewport.height;  // Get PDF page height
//           canvas.width = containerWidth * zoom;  // Adjust width based on container width and zoom
//           canvas.height = height;  // Set height according to PDF height
//           // Render the page on the canvas
//           const renderContext = {
//             canvasContext: ctx!,
//             viewport: viewport,
//           };
//           await page.render(renderContext).promise;
//         };
//         renderPdfPage();
//       }
//     }
//   }, [fileLink, isImage, isPdf, zoom]);  // Re-run effect when fileLink, zoom, or file type changes

//   const handleZoomIn = () => {
//     setZoom(prevZoom => Math.min(prevZoom + 0.2, 3)); // Max zoom level of 3
//   };

//   const handleZoomOut = () => {
//     setZoom(prevZoom => Math.max(prevZoom - 0.2, 0.5)); // Min zoom level of 0.5
//   };

//   return (
//     <div className="col-sm-12 col-md-6">
//       <div className="card card-xl-stretch" style={{ height: '70vh' }}>
//         <div className="card-header border-0">
//           <h3 className="card-title fw-bold text-dark">
//             <KTIcon iconName="screen" className="fs-2x text-primary" />
//             &nbsp; Preview Original Document
//           </h3>
//         </div>

//         {/* Zoom and Fit to Screen controls placed above the canvas */}
//         <div 
//           style={{ 
//             position: 'absolute', 
//             top: '10px', 
//             right: '10px', 
//             zIndex: 10, 
//             opacity: 1, 
//             display: 'flex', 
//             gap: '10px', 
//             flexDirection: 'row', 
//             padding: '5px',
//           }}
//         >
//           <button 
//             onClick={handleZoomOut} 
//             className=" p-0"
//             style={{ 
//               background: 'none', // No background color
//               border: 'none',     // No border
//               padding: 0,  
              
//             }}
//           >
//             <KTIcon iconName="minus-circle" className="fs-2x text-white"  />
//           </button>
//           <button 
//             onClick={handleZoomIn} 
//             className="btn btn-link p-0" 
//             style={{ 
//               background: 'none', 
//               border: 'none', 
//               padding:0,
              
//             }}
//           >
//             <KTIcon iconName="plus-circle" className="fs-2x text-white" />
//           </button>
//           {/* <button 
//             onClick={fitToScreen} 
//             className="btn btn-link p-0" 
//             style={{ 
//               background: 'none', 
//               border: 'none', 
//               padding:0,
             
//             }}
//           >
//             Fit To Screen
//             {/* <KTIcon iconName="expand" className="fs-3x text-grey" /> */}
//           {/* </button> */} 
//         </div>

//         {/* Conditional rendering based on file type */}
//         {fileLink !== '' ? (
//           <div 
//             ref={containerRef} 
//             style={{ 
//               position: 'relative', 
//               width: '100%', 
//               height: 'calc(100% - 40px)', // Leave space for zoom controls 
//               overflow: 'hidden', 
//               overflowX: 'auto', 
//               overflowY: 'auto', 
//               scrollbarWidth: 'thin', 
//               scrollbarColor: 'rgba(128, 128, 128, 0.5) rgba(0, 0, 0, 0)', // Transparent gray scrollbar
//             }}
//           >
//             <canvas ref={canvasRef}></canvas>
//           </div>
//         ) : (
//           <div className="card-body">
//             <h4 className="text-center">Select a document to preview it here</h4>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };




// googledocx code 




import React from 'react';
import { KTIcon } from '../../../../_metronic/helpers';
import { DocumentPreviewProps } from '../DocumentProcessingModel';
import { useAuth } from '../../auth';
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Document, Page } from 'react-pdf';

export const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  fileName,
  doctype,
  fileLink,
}) => {

  console.log("filelink", fileLink)
  const { currentUser } = useAuth();
  const isImage = /\.(jpeg|jpg|png|bmp|webp)$/i.test(fileLink);

  console.log("filename",fileName)
  const generateIframeContent = () => {
    return `
          <html>
            <head>
              <style>
                body {
                  margin: 0;
                  padding: 0;
                  overflow: auto; /* Allow scrolling inside the iframe */
                  height: 100%;
                }
                img {
                  display: block;
                  max-width: 100%;  /* Make the image responsive */
                  height: auto;
                  margin: 0 auto;
                  object-fit: contain; /* Keep the image aspect ratio intact */
                }
              </style>
            </head>
            <body>
              <img src="${fileLink}" />
            </body>
          </html>
        `;
  };
  // Google Docs Viewer link for PDFs
  let googleViewerLink = `https://docs.google.com/viewer?url=${encodeURIComponent(fileLink)}&embedded=true`;
  return (
    <div className="col-sm-12 col-md-6">
      <div className="card card-xl-stretch" style={{ height: "70vh" }}>
        <div className="card-header border-0">
          <h3 className="card-title fw-bold text-dark">
            <KTIcon iconName="screen" className="fs-2x text-primary" />
            &nbsp; Preview Original Document
          </h3>
        </div>
        {fileLink !== '' ? (
          isImage ? (
            <iframe
              srcDoc={generateIframeContent()}
              title="PDF Preview"
              width="100%"
              height="100%"

              style={{
                display: 'block',
                margin: '0 auto',
                overflow: 'auto', // Enable scroll
              }}
            ></iframe>) : (
                <iframe
                src={googleViewerLink}
                title="PDF Preview"
                width="100%"
                height="500px"
            ></iframe>

            // <iframe
            //   src={`/media/preview/${fileName}`}
            //   title="PDF Preview"
            //   width="100%"
            //   height="500px"
            // ></iframe>

            //     <div style={{ height: '500px', width: '100%' ,overflowY:'auto'}}>
            //     <Document file={fileLink}>
            //         <Page pageNumber={1} />
            //     </Document>
            // </div>
          )
        ) : (
          <div className="card-body">
            <h4 className="text-center">Select a document to preview it here</h4>
          </div>
        )}
      </div>
    </div>
  )
};