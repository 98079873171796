import React, { useEffect, useState } from "react";
import { Blocks } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import { KTIcon } from "../../../_metronic/helpers";
import { deleteFile, indexDocument, documentApproval, documentAssignTo, filesByUser, getAllMember, processDocuments, savePdfPreview } from "../../api";
import { useAuth } from "../../modules/auth";
import { ButtonRow } from "../../modules/documentprocessing/ButtonRow";
import { DocumentLibraryView } from "../../modules/documentprocessing/documentlibrary/DocumentLibraryView";
import { UploadDocuments } from "../../modules/documentprocessing/documentlibrary/UploadDocuments";
import { FileDetails } from "../../modules/documentprocessing/DocumentProcessingModel";
import DocumentReviewView from "../../modules/documentprocessing/documentreview/DocumentReviewView";
import { HeaderSection } from "../../modules/documentprocessing/HeaderSection";
import Feedback from "../../modules/documentValidations/Feedback";
// import { saveAs } from 'file-saver';
import { Spinner } from 'react-bootstrap';
import { resolve } from "path";

type Props = {};

type fileType = Array<string>;

interface TeamMemberObject {
  username: string;
  email: string;
  password: string;
  organizationId: number;
  type: string;
}

type MyObject = {
  [key: string]: string[];
};

export default function DocumentProcessingNew({ }: Props) {
  const { currentUser } = useAuth();
  const { doctype } = useParams()

  const [folder, setFolder] = useState<FileDetails[] | null>();
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [result, setResult] = useState<MyObject>({});
  const [resultNew, setResultNew] = useState<MyObject>({});
  const [resObj, setResObj] = useState<MyObject>({});
  const [viewtables, setViewTables] = useState<Array<MyObject>>([]);
  const [tables, setTables] = useState<Array<Array<MyObject>>>([]);
  const [tableHeader, setTableHeader] = useState<string[][]>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const [preview, setPreview] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [downlodingJson, setDownloadingJson] = useState<boolean>(false);
  const [responseJson, setResponseJson] = useState<Array<Array<Object>>>([])
  const [resultView, setResultView] = useState<string>("default")
  const [sasUrl, setSasUrl] = React.useState("");
  const [closeModal, setCloseModal] = useState(false);
  const [downloadFormat, setDownloadFormat] = useState('csv')
  const [respError, setRespError] = useState(false)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [fileUrl, setFileUrl] = useState<string>()
  const [downloadType, setDownloadType] = useState<string>()
  const [teams, setTeams] = useState<string[]>()
  const [feedback, setFeedback] = useState<string>()
  const [teamMember, setTeamMember] = useState<string>()
  const [processAction, setProcessAction] = useState<string>('')
  const [deleteDisabled, setDeleteDisabled] = useState<boolean>(false)
  const [isApproval, setIsApproval] = useState<string>('')
  const [tabButton, setTabButton] = useState<string>("processed")
  const [tab, setTab] = useState<string>("processed")
  const [currentPageReturn, setCurrentPageReturn] = useState<number>(1)
  const [downloadProcess, setDownloadProcess] = useState<boolean>(false)
  const [indexing, setIndexing] = useState<boolean>(false)
  const [processDate, setProcessDate] = useState<string>('')

  useEffect(() => {
    console.log("documentnew", selectedFiles)
  }, [selectedFiles]);


  console.log(processDate)

  //download 
  const handleDownload = async (
    format: string,
    fileUrl?: string,
    originalFileName?: string,
    processDate?: string,
    review?: string
  ) => {
    try {
      console.log("file_url:", fileUrl);

      let responseJson: any = []; // Initialize responseJson as Array<Array<Object>>

      // Process the document only if `review` is not provided
      if (!review) {
        if (!fileUrl) {
          console.error("File URL is missing!");
          return;
        }

        // Temporary response array to hold parsed data
        let tempJsonResp: Array<Array<Object>> = [];
        // Prepare file list for processing
        let fileList: Array<Object> = [{ fileLink: fileUrl }];

        setDownloadProcess(true)
        // Call the `processDocuments` function and get the response
        const res = await processDocuments({
          files: fileList,
          username: currentUser?.username,
          email: currentUser?.email,
          tag: doctype,
          subscribed: currentUser?.subscribed,
        });
        setDownloadProcess(false)

        let responseNew = res.data[fileUrl];
        console.log("Response from API:", responseNew);

        let tempTableHeader: string[][] = []; // Hold table headers temporarily

        // Iterate through the response data
        Object.entries(responseNew).forEach(([key, value]) => {
          console.log(key, "", "type: ", typeof value, value);

          let resJson: Array<Object> = []; // Temporary array for the current key's data

          // If value is an array of objects, process each object
          if (Array.isArray(value) && value.length > 0 && typeof value === "object") {
            let viewKeyList = Object.keys(value[0]); // Extract keys for the first object
            console.log("viewKey", viewKeyList);
            tempTableHeader.push(viewKeyList); // Update table header
            setTableHeader([...tempTableHeader]); // Persist table header in state

            // Parse and format each object in the array
            value.forEach((val) => {
              let parsedObj: { [key: string]: string } = {};
              Object.entries(val).forEach(([itemKey, itemValue]) => {
                parsedObj[itemKey] = String(itemValue); // Convert all values to string
              });
              resJson.push(parsedObj); // Add parsed object to the response JSON
            });
          }

          // Append the processed data to the main temporary array
          if (resJson.length > 0) {
            tempJsonResp.push(resJson);
          }
        });

        // Ensure that the final responseJson is an Array<Array<Object>>
        responseJson = tempJsonResp.length > 0 ? tempJsonResp[0] : [];


        let tempResultJsonData: MyObject = {};
        console.log("Length of resultNew:", Object.keys(responseNew).length);

        let result: MyObject = {}
        result = responseNew
        if (Object.keys(result).length > 0) {
          Object.entries(result).forEach(([key, value]) => {
            if (typeof value !== "object") {
              tempResultJsonData[key] = value;
            }
          });
        }

        console.log("Metadata added to result:", tempResultJsonData);

        // Insert metadata at the start of `tempJsonFile`
        responseJson.unshift(tempResultJsonData);

      }

      console.log("Parsed response outside function:", responseJson);

      // Set the download format
      setDownloadFormat(format);

      // Extract file name without extension
      const trimmedFileName = originalFileName?.replace(/\.[^/.]+$/, "");
      console.log("trimmedFileName:", trimmedFileName);

      // Extract and format the process date
      const trimmedProcessDate = processDate?.split("T")[0];
      console.log("trimmedProcessDate:", trimmedProcessDate);

      // Set download type and call respective download functions
      setDownloadType(format);

      if (format === "csv" || format === "xlsx") {
        handleCSVDownload(
          format,
          trimmedFileName || "",
          trimmedProcessDate || "",
          responseJson || [] // Pass the two-dimensional array responseJson
        );
      } else if (format === "txt" || format === "json") {
        handleJsonDownload(
          format,
          trimmedFileName || "",
          trimmedProcessDate || "",
          responseJson || [],
        );
      } else {
        console.warn("Invalid format selected for download.");
      }
    } catch (error) {
      console.error("Error occurred during download:", error);
    }
  };



  //handle delete
  const handleDelete = (userName: string, tag: string, fileUrl: string) => {
    setDeleteDisabled(true);
    deleteFile({
      "userName": userName,
      "tag": tag,
      "fileUrl": fileUrl,
    }).then(() => {
      setDeleteDisabled(false);
      // Make API call to get files by user
      filesByUser({ email: currentUser?.email, tag: doctype, username: currentUser?.username }).then((res) => {
        setFolder(res.data); // Set folder state with response data
      });
    })
  };

  const handleCSVDownload = (
    format: string,
    filename: string,
    processDate: string,
    tempJson: any
  ) => {
    try {
      console.log("Input tempJson:", tempJson);

      // Reset `tempJsonFile` for each call
      let tempJsonFile: Array<Object> = [];

      // Handle empty `tempJson`
      if (!tempJson || tempJson.length === 0) {
        tempJsonFile = responseJson[0] ? [...responseJson[0]] : []; // Ensure immutability

        let tempResultJsonData: MyObject = {};
        console.log("Length of resultNew:", Object.keys(resultNew).length);

        if (Object.keys(resultNew).length > 0) {
          Object.entries(resultNew).forEach(([key, value]) => {
            if (typeof value !== "object") {
              tempResultJsonData[key] = value;
            }
          });
        }

        console.log("Metadata added to result:", tempResultJsonData);

        // Insert metadata at the start of `tempJsonFile`
        tempJsonFile.unshift(tempResultJsonData);
        console.log("Final tempJsonFile with metadata:", tempJsonFile);
      } else {
        // Use the provided `tempJson`
        console.log("tempJson:", tempJson);
        tempJsonFile = [...tempJson]; // Ensure immutability
      }

      // Create the file in the browser
      const jsonFileName = filename.slice(0, filename.indexOf(".pdf"));
      const workbook = utils.book_new();
      const worksheet = utils.json_to_sheet(tempJsonFile);
      utils.book_append_sheet(workbook, worksheet, "");

      // Generate and download the file
      const outputFileName = `${filename}_${processDate}`;
      format === "csv"
        ? writeFile(workbook, `${outputFileName}.csv`)
        : writeFile(workbook, `${outputFileName}.xlsx`);

      console.log(`File successfully created: ${outputFileName}`);
    } catch (error) {
      console.error("Error in handleCSVDownload:", error);
    }
  };



  const handleJsonDownload = (
    format: string,
    fileName: string,
    processDate: string,
    tempJson: any
  ) => {
    try {
      setDownloadingJson(true);
  
      let tempJsonFile: Array<any> = []; // Reset tempJsonFile on every call
  
      // If tempJson is empty or undefined, use responseJson as a fallback
      if (!tempJson || tempJson.length === 0) {
        tempJsonFile = responseJson[0] ? [...responseJson[0]] : [];
  
        // Add metadata from resultNew to tempJsonFile
        const tempResultJsonData: MyObject = {};
        if (Object.keys(resultNew).length > 0) {
          Object.entries(resultNew).forEach(([key, value]) => {
            if (typeof value !== "object") {
              tempResultJsonData[key] = value;
            }
          });
        }
  
        console.log("Metadata added to result:", tempResultJsonData);
        tempJsonFile.unshift(tempResultJsonData); // Insert metadata at the start
      } else {
        // Use provided tempJson as the file content
        tempJsonFile = [...tempJson]; // Ensure immutability
      }
  
      console.log("Final tempJsonFile:", tempJsonFile);
  
      // Generate the JSON file name
      const jsonFileName = `${fileName}_${processDate}`;
      const json = JSON.stringify(tempJsonFile, null, 2);
  
      // Create a Blob and URL for the file
      const blob = new Blob([json], { type: "application/json" });
      const href = URL.createObjectURL(blob);
  
      // Create a temporary download link
      const link = document.createElement("a");
      link.href = href;
      link.download = format === "json" ? `${jsonFileName}.json` : `${jsonFileName}.txt`;
      document.body.appendChild(link);
      link.click();
  
      // Cleanup
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
  
      console.log(`File downloaded: ${link.download}`);
    } catch (error) {
      console.error("Error in handleJsonDownload:", error);
    } finally {
      setDownloadingJson(false); // Ensure this is reset even if an error occurs
    }
  };
  

  const handleProcess = (fileLink?: string, isApproval: string = '', originalFileName?: string): Promise<void> => {
    // Set initial states
    setIsApproval(isApproval)
    console.log("selected file in document", selectedFiles)
    console.log(fileLink)
    console.log(isApproval)
    console.log(originalFileName)
    if (isApproval == 'downloadButton') {
      setDownloadProcess(true)
    } else {
      setProcessing(true); // Set processing state to true
    }
    setRespError(false); // Reset response error state
    let fileList: Array<Object> = []; // Initialize an array to hold file objects
    if (originalFileName == undefined && selectedFiles.length > 0) {
      // If no fileLink is provided, iterate through selectedFiles
      selectedFiles.forEach((selectedFile) => {
        // Create file object for each selected file and push it to fileList
        let fileObj = {
          fileLink: selectedFile
        };
        fileList.push(fileObj);
      });
      setSelectedFiles([])
      setResultNew({}); // Clear result new
      return new Promise<void>((resolve, reject) => {
        processDocuments({
          files: fileList,
          username: currentUser?.username,
          email: currentUser?.email,
          tag: doctype,
          subscribed: currentUser?.subscribed
        }).then((res) => {

          alert("Your Documents has been processed successfully")
          // Make API call to get files by user
          filesByUser({ email: currentUser?.email, tag: doctype, username: currentUser?.username }).then((res) => {
            setFolder(res.data); // Set folder state with response data
          });
          setTab("processed")
          setProcessing(false); // Set processing state to false
          setDownloadProcess(false);
          resolve(); // Resolve the promise


        }).catch((error) => {
          setSelectedFiles([])
          setRespError(true);
          reject(error); // Reject the promise with error
          if (error.response) { // Check if error.response exists
            if (error.response.status === 426) {
              alert(error.response.data.data);
              setProcessing(false);
              setDownloadProcess(false);
            } else if (error.response.status === 413) {
              alert("Resume data limit exceeded. Please try another resume.");
              setProcessing(false);
              setDownloadProcess(false);
            } else if (error.response.status === 500) {
              alert(error.response.data.message);
              setProcessing(false);
              setDownloadProcess(false);
            } else {
              alert("Facing Network Issue. Please Try again.");
              setProcessing(false);
              setDownloadProcess(false);
            }
          } else {
            alert("Oops! Something went wrong.\n\nWe encountered an unexpected issue while processing this file. Please try again with different file. If the problem persists, contact our support team for assistance.");
            setProcessing(false);
            setDownloadProcess(false);
          }

        });
      });

    }

    setViewTables([]); // Clear view tables
    setResponseJson(() => []); // Clear response JSON
    setSelectedFiles([]); // Clear selected files
    setResultNew({}); // Clear result new



    // If a fileLink is provided, add it to the selectedFiles array

    if (fileLink) {
      let tempSelectedFiles = selectedFiles;
      tempSelectedFiles.push(fileLink);
      setSelectedFiles(tempSelectedFiles);
      // Create file object and push it to fileList
      let fileObj = {
        fileLink: fileLink
      };
      fileList.push(fileObj);

      console.log(fileLink);
      setFileUrl(fileLink);
      console.log("fileLink", fileLink)
      // if (originalFileName?.toLowerCase().endsWith('.pdf')) {
      //   savePdfPreview({
      //     "original_file_name": originalFileName,
      //     "uploaded_file_url": fileLink,
      //     "useremail": currentUser?.email,
      //     "tag":doctype,
      //     "username":currentUser?.username,
      //   }).then(() => { })
      // }

      return new Promise<void>((resolve, reject) => {
        processDocuments({
          files: fileList,
          username: currentUser?.username,
          email: currentUser?.email,
          tag: doctype,
          subscribed: currentUser?.subscribed
        }).then((res) => {

          // Process response data
          let tempTableHeader: string[][] = []; // Temporary array to hold table headers
          let tempJsonResp: Object[][] = []; // Temporary array to hold JSON response
          let responseNew = res.data[fileLink ? fileLink : '']; // Get response data
          console.log(responseNew)
          setResultNew(responseNew); // Set result new


          // Iterate through response data
          Object.entries(responseNew).map(([key, value], index) => {
            // console.log("key",key," ","type: ", typeof (value));

            let resJson: Array<Object> = [];

            // Check if value is an array and not empty
            if (Array.isArray(value) && value.length > 0 && typeof value[0] === "object") {
              let viewKeyList = Object.keys(value[0] || {}); // Get keys of first object in the array
              console.log(viewKeyList);
              tempTableHeader.push(viewKeyList); // Push keys to temporary table header array
              setTableHeader([...tempTableHeader]); // Set table header

              // Iterate through array elements
              value.map((val) => {
                var abcObj: { [key: string]: string } = {}; // Create object to hold key-value pairs
                Object.entries(val).map(([itemKey, itemValue], index) => {
                  abcObj[itemKey] = String(itemValue); // Convert each value to string and assign to object
                });
                resJson.push(abcObj); // Push object to response JSON array
              });
            }
            // Check if response JSON array is not empty and push it to temporary response array
            resJson.length > 0 && tempJsonResp.push(resJson);
          });

          sessionStorage.setItem("response", JSON.stringify(res.data)); // Store response data in session storage
          setResponseJson(tempJsonResp); // Set response JSON
          console.log("tempjson", responseJson);

          // Make API call to get files by user
          filesByUser({ email: currentUser?.email, tag: doctype, username: currentUser?.username }).then((res) => {
            setFolder(res.data); // Set folder state with response data
          });

          setProcessing(false); // Set processing state to false
          setDownloadProcess(false);
          resolve(); // Resolve the promise
        }).catch((error) => {
          setRespError(true);
          reject(error); // Reject the promise with error
          if (error.response) { // Check if error.response exists
            if (error.response.status === 426) {
              alert(error.response.data.data);
              setProcessing(false);
              setDownloadProcess(false);
            } else if (error.response.status === 413) {
              alert("Resume data limit exceeded. Please try another resume.");
              setProcessing(false);
              setDownloadProcess(false);
            } else if (error.response.status === 500) {
              alert(error.response.data.message);
              setProcessing(false);
              setDownloadProcess(false);
            } else {
              alert("Facing Network Issue. Please Try again.");
              setProcessing(false);
              setDownloadProcess(false);
            }
          } else {
            alert("Oops! Something went wrong.\n\nWe encountered an unexpected issue while processing this file. Please try again with different file. If the problem persists, contact our support team for assistance.");
            setProcessing(false);
            setDownloadProcess(false);
          }
        });
      });
    }
    return Promise.resolve();
  };

  const handleDocumentApproval = (approval: string) => {
    console.log(selectedFiles)
    setIsApproval('')
    if (selectedFiles.length > 0) {
      setProcessAction(approval)
      let files: Array<Object> = [];
      selectedFiles.forEach((selectedFile) => {
        let fileObj = {
          file_url: selectedFile
        };
        files.push(fileObj);
      });
      documentApproval({
        useremail: currentUser?.email,
        files: files,
        approval: approval,
        feedback: feedback ? feedback : "",
        userName: currentUser?.username,
        tag: doctype,
      }).then((res) => {
        setProcessAction('')
        alert(res.message)
        setIsApproval('accepted')
      })
    } else {
      alert("No file selected")
    }
  }

  const handleAssingMember = (e: any) => {
    // e.preventDefault()
    if (selectedFiles.length > 0) {
      setProcessAction("assinging")
      let files: Array<Object> = [];
      selectedFiles.forEach((selectedFile) => {
        let fileObj = {
          file_url: selectedFile
        };
        files.push(fileObj);
      });
      documentAssignTo({
        useremail: currentUser?.email,
        files: files,
        assignTo: teamMember
      }).then((res) => {
        setProcessAction('')
        setSelectedFiles([]);
        alert(res.message)
        setRefresh(true)
      })
    } else {
      alert("No file selected")
    }
  }

  const handleSubmitFeedback = (e: any) => {
    setFeedback(e.target.value);
    if (selectedFiles.length > 0) {
      setProcessAction('rejected')
      let files: Array<Object> = [];
      selectedFiles.forEach((selectedFile) => {
        let fileObj = {
          file_url: selectedFile
        };
        files.push(fileObj);
      });
      documentApproval({
        useremail: currentUser?.email,
        files: files,
        approval: 'rejected',
        feedback: feedback ? feedback : "",
        userName: currentUser?.username,
        tag: doctype,
      }).then((res) => {
        setProcessAction('')
        setSelectedFiles([]);
        alert(res.message)
        setRefresh(true)
      })
    } else {
      alert("No file selected")
    }
  }

  const handleIndexing = (fileLink?: string) => {
    console.log("Indexing", selectedFiles)
    setRespError(false)
    setIndexing(true)
    let fileList: Array<Object> = [];
    if (selectedFiles.length > 0) {
      selectedFiles.forEach((selectedFile) => {

        let fileObj = {
          fileLink: selectedFile
        };
        fileList.push(fileObj);
      });
      setSelectedFiles([])
      indexDocument({
        files: fileList,
        username: currentUser?.username,
        tag: doctype,
      }).then((res) => {
        setIndexing(false)
        alert("Your Documents has been indexed successfully");

        // Make API call to get files by user
        filesByUser({ email: currentUser?.email, tag: doctype, username: currentUser?.username }).then((res) => {
          setFolder(res.data); // Set folder state with response data
        });

        setTab("processed")
      }).catch((error) => {
        setSelectedFiles([])
        setRespError(true);
        alert(error.response.data.message)
        setIndexing(false)

      });
    }

  }

  useEffect(() => {
    getAllMember({ organizationId: currentUser?.organizationId }).then((res) => {
      //  Filtering out emails of objects with type !== 'superadmin'
      const emails = res.data
        .filter((user: TeamMemberObject) => user.type !== 'superadmin')
        .map((user: TeamMemberObject) => user.email);

      setTeams(emails)
    });
  }, [])
  console.log("currentpagereturn :", currentPageReturn)
  const toggleState = () => {
    setPreview(prevState => !prevState); // Invert the current state value

  };

  useEffect(() => {
    setFolder(null)
    filesByUser({ email: currentUser?.email, tag: doctype, username: currentUser?.username }).then((res) => {
      setFolder(res.data);
    });

  }, [doctype]);

  useEffect(() => {
    console.log("Hiting fetch file here")
    if (refresh) {
      let tempFolder: FileDetails[] = []
      setFolder(tempFolder)
      filesByUser({ email: currentUser?.email, tag: doctype, username: currentUser?.username }).then((res) => {
        setFolder(res.data);
        setRefresh(false)
      });
    }
  }, [refresh])

  // useEffect(() => {


  // }, [downloadType,fileNameDownload]); // Run this effect when downloadType changes

  return (
    <div>
      <div
        className={processing || downloadProcess || indexing ? 'd-flex' : 'd-none'}
        style={{
          width: '100vw',
          height: '100vh',
          zIndex: 100,
          backgroundColor: '#5e627861',
          position: 'fixed',
          top: 0,
          left: 0,
        }}
      ></div>

      <div
        className={deleteDisabled ? 'd-flex align-items-center justify-content-center' : 'd-none'}
        style={{
          width: '100vw',
          height: '100vh',
          zIndex: 100,
          backgroundColor: '#5e627861',
          position: 'fixed',
          top: 0,
          left: 0,
        }}
      >
        <Spinner animation="border" role="status" variant="light" style={{ width: '3rem', height: '3rem' }}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>

      {/* Upload Document */}
      <div className="modal fade" id="kt_modal_assosiate_student" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Upload Document</h2>
              <button
                className="btn btn-sm btn-primary text-dark"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </button>
            </div>
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              {
                currentUser?.usertype == 'superadmin' ?
                  <UploadDocuments closeModal={closeModal} setRefresh={setRefresh} setCloseModal={setCloseModal} /> :
                  <p className="fs-1 fw-bold text-center">Feature Disabled</p>
              }
            </div>
          </div>
        </div>
      </div>

      {/* Preview Document */}
      <div className="modal fade" id="kt_modal_preview" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Preview Document</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <div>
                <iframe
                  src={sasUrl}
                  title="PDF Preview"
                  width="100%"
                  height="500px"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Assign Team */}
      {/* <div
        className="modal fade"
        id="kt_modal_assign"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Assign Document to Team Member</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <AssignDocument
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              setProcessAction={setProcessAction}
              setRefresh={setRefresh}
            />
          </div>
        </div>
      </div> */}

      {/* Provide Feedback */}
      <div
        className="modal fade"
        id="kt_modal_feedback"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Please Provide Feedback about the Processed Document</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <Feedback
              setProcessAction={setProcessAction}
              setIsApproval={setIsApproval}
              tag={doctype ? doctype : ''}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              setRefresh={setRefresh}
            />
          </div>
        </div>
      </div>

      <div className="mx-2 mb-xl-8" style={{ borderRadius: '0px', minHeight: '90vh' }}>
        {/* begin::Header */}
        <HeaderSection doctype={doctype ? doctype : ""} />
        {/* end::Header */}

        <ButtonRow
          tables={tables}
          handleProcess={handleProcess}
          handleIndexing={handleIndexing}
          resultNew={resultNew}
          responseJson={responseJson}
          setResultView={setResultView}
          resultView={resultView}
          toggleState={toggleState}
          preview={preview}
          tab={tabButton}
          processing={processing}
          indexing={indexing}
          selectedFiles={selectedFiles}
        />

        {folder && folder.length > 0 ? (
          <div className="card-body pt-5">
            {
              preview ?
                <DocumentReviewView
                  fileName={fileName}
                  doctype={doctype ? doctype : ""}
                  resultNew={resultNew}
                  resultView={resultView}
                  tableHeader={tableHeader}
                  responseJson={responseJson}
                  processAction={processAction}
                  selectedFiles={selectedFiles}
                  fileUrl={fileUrl ? fileUrl : ""}
                  processing={processing}
                  isApproval={isApproval}
                  processDate={processDate}
                  handleDocumentApproval={handleDocumentApproval}
                  handleDownload={handleDownload}

                /> :
                <>
                  {
                    folder &&
                    <DocumentLibraryView
                      doctype={doctype ? doctype : "Document"}
                      documents={folder}
                      setRefresh={setRefresh}
                      preview={preview}
                      toggleState={toggleState}
                      setFileName={setFileName}
                      onReview={handleProcess}
                      handleDownload={handleDownload}
                      handleDelete={handleDelete}
                      setSelectedFiles={setSelectedFiles}
                      setProcessDate={setProcessDate}
                      setTabButton={setTabButton}
                      processing={processing}
                      setTab={setTab}
                      tabProp={tab}
                      currentPageReturn={currentPageReturn}
                      setCurrentPageReturn={setCurrentPageReturn}
                      indexing={indexing}
                    />
                  }
                </>
            }
          </div>
        ) : (
          <div className="row">
            {folder?.length == 0 ?
              <div className="card-body pt-5">
                <p className="fw-bold fs-2 text-center">No Files Present. Please Upload New Files</p>
              </div>
              :
              <>
                <div
                  className={folder?.length != 0 ? 'd-flex align-items-center justify-content-center' : 'd-none'}
                  style={{
                    width: '100vw',
                    height: '100vh',
                    zIndex: 100,
                    backgroundColor: '#5e627861',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                  }}
                >

                </div><Blocks
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                />
              </>
            }
          </div>
        )}
      </div>
    </div>
  );
}