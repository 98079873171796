import clsx from 'clsx';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { demoUserRegistration } from '../../../api';


export function DemoForm() {
    const [loading, setLoading] = useState(false);
    const [hasErrors, setHasErrors] = useState<string | undefined>(undefined);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const navigate = useNavigate();

    // Validation schema for email, phonenumber, and organization
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email address').required('Email is required'),
        phonenumber: Yup.string().matches(
            /^[0-9]{10}$/,
            'Phone number must be exactly 10 digits'
        ).required('Phone number is required'),
        organization: Yup.string().required('Organization is required'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            phonenumber: '',
            organization: '',
        },
        validationSchema: validationSchema,
        onSubmit: async(values, { setSubmitting }) => {
            const { email, phonenumber, organization } = values;
            setLoading(true);

            try {
                demoUserRegistration({ "email": email, "organization_name": organization, "phone_number": phonenumber }).then(async (res) => {
                   alert("Thank you for registering for the demo.")
                 
                   setTimeout(() => {
                    window.location.replace('https://demo.optira.ai/');
                  }, 800);
                    
                    setLoading(false);
                }).catch((e) => {
                    if (e.response) { // Check if error.response exists
                        alert("We encountered an issue while registering your demo account. Please try again later.");
                        setSubmitting(false);
                        setLoading(false);
                    } else {
                        alert("Oops! Something went wrong. Please try again later.");
                        setSubmitting(false);
                        setLoading(false);
                    }
                });
            } catch (error) {
                console.error(error);
                setSubmitting(false);
                setLoading(false);
            }

        },
    });

    return (
        <div
            className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
            style={{
                backgroundImage: `url(${toAbsoluteUrl(
                    "/media/illustrations/sketchy-1/14.png"
                )})`,
            }}
        >
            <div className="d-flex flex-column flex-column-fluid p-10 pb-lg-20">
                <a href="https://optira.ai/" target="_blank" rel="noreferrer">
                    <div className="d-flex align-items-start mb-2">
                        <img
                            alt="Logo"
                            src={toAbsoluteUrl("/media/logos/default.png")}
                            className="h-65px"
                        />
                        <div className="d-flex flex-column ml-3">
                            <span className="fw-bolder fs-1 text-primary">Optira</span>
                            <span className="fs-6 text-white">AI-based Document Processing & Analytics Platform</span>
                        </div>
                    </div>
                </a>

                <div
                    className="w-lg-500px bg-body rounded p-10 p-lg-15 mx-auto"
                    style={{ boxShadow: '6px 6px 19px #0c101b, -6px -6px 19px #1c263f' }}
                >
                    <form className="form w-100 mt-4" noValidate onSubmit={formik.handleSubmit}>
                        <div className="text-center mb-10">
                            <h1 className="text-dark fw-bolder mb-3">Registration For Demo</h1>
                            {/* <div className="text-white-500 fw-semibold fs-6">
                                {loading ? 'Processing...' : ''}
                            </div> */}
                        </div>

                        {hasErrors && (
                            <div className="mb-lg-15 alert alert-danger">
                                <div className="alert-text font-weight-bold">{hasErrors}</div>
                            </div>
                        )}

                        <div className="fv-row mb-8">
                            <label className="form-label fw-bolder text-gray-900 fs-6">Email</label>
                            <input
                                type="email"
                                {...formik.getFieldProps('email')}
                                className={clsx('form-control bg-transparent', {
                                    'is-invalid': formik.touched.email && formik.errors.email,
                                    'is-valid': formik.touched.email && !formik.errors.email,
                                })}
                            />
                            {formik.touched.email && formik.errors.email && (
                                <div className="fv-help-block">
                                    <span role="alert">{formik.errors.email}</span>
                                </div>
                            )}
                        </div>

                        <div className="fv-row mb-8">
                            <label className="form-label fw-bolder text-gray-900 fs-6">Phone Number</label>
                            <input
                                type="text"
                                {...formik.getFieldProps('phonenumber')}
                                className={clsx('form-control bg-transparent', {
                                    'is-invalid': formik.touched.phonenumber && formik.errors.phonenumber,
                                    'is-valid': formik.touched.phonenumber && !formik.errors.phonenumber,
                                })}
                            />
                            {formik.touched.phonenumber && formik.errors.phonenumber && (
                                <div className="fv-help-block">
                                    <span role="alert">{formik.errors.phonenumber}</span>
                                </div>
                            )}
                        </div>

                        <div className="fv-row mb-8">
                            <label className="form-label fw-bolder text-gray-900 fs-6">Organization</label>
                            <input
                                type="text"
                                {...formik.getFieldProps('organization')}
                                className={clsx('form-control bg-transparent', {
                                    'is-invalid': formik.touched.organization && formik.errors.organization,
                                    'is-valid': formik.touched.organization && !formik.errors.organization,
                                })}
                            />
                            {formik.touched.organization && formik.errors.organization && (
                                <div className="fv-help-block">
                                    <span role="alert">{formik.errors.organization}</span>
                                </div>
                            )}
                        </div>

                        {/* Center the Submit Button */}
                        <div className="d-flex justify-content-center mt-5">
                            <button
                                type="submit"
                                className="btn btn-success mb-5 w-50 text-center fs-4"
                                disabled={loading}
                            >
                                {loading ? (
                                    <span className="indicator-progress" style={{ display: 'block' }}>

                                        Submitting
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                ) : (
                                    'Submit'
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div className="p-10">
                <div className="row">
                    <div className="col-4">
                        <div className="">
                            <div className="d-flex fw-bold fs-4 align-items-start my-2">
                                <span>A Real-world AI Product by
                                    <a
                                        href="https://thirdeyedata.ai/"
                                        target="_blank"
                                        className="text-hover-primary px-2"
                                        style={{ color: "#fff", textTransform: "uppercase", fontWeight: "bold" }}
                                        rel="noreferrer"
                                    >
                                        <img src={toAbsoluteUrl("/media/logos/thirdeyelogo-horizontal.png")} alt="Thirdeye data" height={25} className="mb-3" /> &nbsp;
                                    </a>
                                </span>
                            </div>
                            <span className="fw-light fs-7">All Rights Reserved © 2024 - ThirdEye Data (USA)</span>
                        </div>
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-center fw-bold fs-2">
                            <a
                                href="https://optira.ai/"
                                target="_blank"
                                className="text-hover-primary px-2"
                                style={{ color: "#fff" }}
                                rel="noreferrer"
                            >
                                Sales
                            </a>

                            <a
                                href="https://optira.ai/submit-ticket/"
                                target="_blank"
                                className="text-hover-primary px-2"
                                style={{ color: "#fff" }}
                                rel="noreferrer"
                            >
                                Support
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
